<template>
  <div class="login_content  fadeIn fourth">
    <!--        <div class="login_panel_overlay">-->
    <!--        </div>-->
    <div class="login_panel">
      <div class="login_logo">
        <img src="../../assets/images/login/login-logo.png" alt="" style="width: 230px">
      </div>
      <div class="login_items login_items_rel" v-if="isMaintaining == false">
        <div class="ipt">
          <input type="text" v-model="user.username" placeholder="아이디" @keyup.enter="doLogin"/>
        </div>
        <div class="ipt">
          <input type="password" v-model="user.passwd" placeholder="비밀번호" @keyup.enter="doLogin">
        </div>
        <div class="btns">
          <router-link tag="button" to="" @click.native="doLogin" class="login">로그인</router-link>
          <router-link tag="button" to="" class="register" @click.native="toregister">회원가입</router-link>
          <router-link tag="button" to="/notloginask" class="notloginask">비회원문의</router-link>
        </div>
        <div class="err" v-show="errmessage != null">
          <i class="fa fa-warning"></i> {{ errmessage }}
        </div>
      </div>
      <div class="login_items login_items_rel" v-if="isMaintaining">
        <div style="opacity: .9;padding: 20px;color: #0b1423;background-color: #1f1f24"
             v-html="content">
        </div>
      </div>
      <div class="login-tel" style="">
        <a href="https://t.me/wine365" target="_blank">
          <img alt="" src="../../assets/images/banner/dst/login-tel.jpg" style="width: 100%">
        </a>
      </div>
    </div>

    <register-comp></register-comp>
    <div class="popup_section popup_section_postion"
         v-show="popup.isShow">

      <div class="pop_box">
        <div class="event_img">
          <img src="../../assets/images/banner/dst/login-popup-002.jpg" alt="" style="width: 100%;">
        </div>
      </div>
      <div class="pfoot" style="">
        <div @click="close24(popup)" style="text-align: left;cursor: pointer">
          <i class="fa fa-square"></i> 오늘하루 그만보기
        </div>
        <div style="text-align: right;cursor: pointer">
          <button class="btn-close" @click="close(popup)">닫기</button>
        </div>
      </div>

    </div>
    <div class="popup_section popup_section_postion02"
         v-show="popup2.isShow">

      <div class="pop_box">
        <div class="event_img">
          <img src="../../assets/images/banner/dst/login-popup-001.jpg" alt="" style="width: 100%;">
        </div>
      </div>
      <div class="pfoot" style="">
        <div @click="close24(popup2)" style="text-align: left;cursor: pointer">
          <i class="fa fa-square"></i> 오늘하루 그만보기
        </div>
        <div style="text-align: right;cursor: pointer">
          <button class="btn-close" @click="close(popup2)">닫기</button>
        </div>
      </div>

    </div>

  </div>


</template>

<script>
import {getSiteStatus, getUserInfo, login, logout} from "../../network/userRequest";
import {loginRegisterMixin} from "../../common/mixin";
import sportsConst from "../../common/sportsConst";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../../store/mutation-types";
import TopbarComp from "../../components/TopbarComp";
import LeftBarComp from "../../components/LeftBarComp";
import RightBarComp from "../../components/RightBarComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import RegisterComp from "../../components/RegisterComp";

export default {
  name: "login",
  components: {
    RegisterComp,
    SportsLeftBarComp, RightBarBannerComp, FootComp, RightBarComp, LeftBarComp, TopbarComp
  },
  mixins: [loginRegisterMixin],
  data() {
    return {
      user: {
        username: '',
        passwd: '',
      },
      isShowMessage: false,
      content: '',
      isMaintaining: false,
      errmessage: null,
      popup: {isShow: true, id: 1020304050},
      popup2: {isShow: true, id: 1020304052},
    }
  },
  methods: {
    doLogin() {

      this.$store.commit(RECEIVE_SHOW_LOADING);
      login(this.user).then(res => {
        if (res.data.success) {
          this.errmessage = null
          getUserInfo().then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.$store.state.isLogin = true;
            this.$store.state.userInfo = res.data.data
            //this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            //window.sessionStorage.setItem("isLogin", 'true');
            this.$router.push('/main').catch(error => {
              console.info(error.message)
            });
          }, err => {
            console.log(err)
          })
        } else {
          this.$store.commit(RECEIVE_HIDE_LOADING);
          // this.$swal({
          //     title: res.data.msg,
          //     type: 'error',
          //     showCancelButton: false,
          //     showConfirmButton: true
          // })
          this.errmessage = res.data.msg;
        }
      })
    },

    toregister() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
      }
    },
    close24(item) {
      this.$set(item, 'isShow', false)
      this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
    },
    close(item) {
      //this.$store.state.popups.push(item.id)
      this.$set(item, 'isShow', false)
    },


  },
  created() {
    getSiteStatus().then(res => {
      if (!res.data.success) {
        this.isMaintaining = true
      }
      this.content = res.data.msg
    })
    let cookiePopupStatus = this.$cookies.get('pop' + this.popup.id)
    this.$set(this.popup, 'isShow', cookiePopupStatus == null)
    let cookiePopupStatus2 = this.$cookies.get('pop' + this.popup2.id)
    this.$set(this.popup2, 'isShow', cookiePopupStatus2 == null)
  },
  computed: {
    allowUsername() {
      return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
    },
    allowPasswd() {
      return /^.{3,30}$/.test(this.passwd)
    }
  }
}
</script>

<style scoped>
.login_content {
  width: 100%;
  height: 100vh;
  background: #09090a url("../../assets/images/login/login-bg-pc.jpg") no-repeat center center;
  background-size: 100%;
}

.login_panel {
  width: 450px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-55%) scale(1);
  transform: translateX(-50%) translateY(-55%) scale(1);
  z-index: 10;
  background: rgba(10, 10, 10, .8);
  border-image: linear-gradient(to right, #777778, #777778) 1;
  box-shadow: 0 0 10px #777778;
  border-radius: 10px;
}

.login_panel .login_logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) scale(1);
}

.login_items_rel {
  z-index: 2;
  padding-top: 10px;
}

.login_items {
  width: 100%;
  height: 240px;
  border-radius: 10px;
  position: absolute;
  top: 100px;
  left: 50%;
  -webkit-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ipt {
  width: 80%;
  height: 55px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  --box-shadow: 0 0 5px #fee394;
}

.ipt input {
  width: 100%;
  height: 50px;
  border-radius: 1px;
  border: 1px solid #5d5f5e;
  background-color: #050507;
  --box-shadow: inset 2px 3px 13px rgba(40, 86, 110, 0.8);
  text-align: center;
  color: #fff9ff;
}

.btns {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btns button {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.btns .login {
  background-color: #4c4c4e;
  background-size: 100%;
}

.btns .register {
  background-size: 100%;
  background-color: #232323;
}

.btns .notloginask {
  background-color: #232323;
  height: 35px;
  line-height: 35px;
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.fourth {
  animation-delay: 0.3s;
  -webkit-animation-name: fourth1;
  animation-name: fourth1;
}


.popup_section {
  position: absolute;
  background-color: #2d3137;
  color: #fff9ff;
  font-weight: 600;
  z-index: 999999;
}

.popup_section_postion {
  top: 25%;
  left: 10%;
}

.popup_section_postion02 {
  top: 25%;
  right: 10%;
}

.popup_section h4 {
  line-height: 20px;
  height: 20px;
  width: 100%;
  font-size: 12px;
  padding: 20px;
  color: #cca4fd;
}

.popup_section .btn-close {
  padding: 8px 8px;
  background-color: #c61d1e;
}

.pop_box {
  box-sizing: border-box;
  --padding: 20px;

}

.pop_box h2 span:hover {
  color: #f19f09;
}

.popup_section .pfoot {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  line-height: 40px
}

.pfoot div {
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  padding: 0 5px;
}

.login-tel {
  margin-top: 430px;
  padding: 0 50px
}

@media screen and (max-width: 1024px) {
  .login_content {
    background: url("../../assets/images/login/login-bg-mobile.jpg") no-repeat center center;
    background-size: cover;
  }

  .login_panel {
    width: 365px;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1) !important;
    transform: translateX(-50%) translateY(-50%) scale(1) !important;
  }

  .login_panel_overlay {
    width: 370px;
    height: 420px;
  }

  .login-tel {
    margin-top: 420px;
    padding: 0 40px
  }

  .popup_section {
    position: absolute !important;
    z-index: 9999999;

    width: 100% !important;
    padding: 0 5px !important;
  }

  .popup_section_postion {
    left: 0 !important;
    top: 112px !important;
  }

  .popup_section_postion02 {
    right: 0 !important;
    top: 112px !important;
  }

  .popup_section .pfoot {
    line-height: 60px;
  }
}

.err {
  color: #f80000;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  animation: sansan 700ms infinite;
  -webkit-animation: sansan 700ms infinite;
  margin-top: 15px;
}

@keyframes fourth1 {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
}

@keyframes sansan {
  from {
    opacity: 1.0;
  }
  70% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes sansan {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
</style>